import {  Box, Button, Grid, Paper, Typography } from "@mui/material";
import { LoadableImage } from "../LoadableImage";
import Leaderboard from "../Leaderboard";
import ShuffleIframe from "../ShuffleIframe";


const LandingPage=()=> {

const banner = 'https://firebasestorage.googleapis.com/v0/b/cluckers-64eda.appspot.com/o/B6CFE8E2-848F-405C-8C45-12CCF0886C8C.png?alt=media&token=f401b83c-9ac9-42c1-8815-d593c7bc1fda';

return (
        <Grid container spacing={1} sx={{pt:2}}>
           <Grid item xs={12}>
                <Paper elevation={4}>
                  <Box component={'img'} src={banner} height={'auto'} width={'100%'}></Box>
                <Box sx={{padding:2}}>
                <Typography variant="h4">Welcome to Algo Cluckers!</Typography>
                 <Typography variant="body1">A fun series of hand drawn Cluckers done by AlgoAddict420 that live on the Algorand blockchain!</Typography>
                </Box>


               </Paper>
               </Grid>
               <Grid item xs={12}>
                  <Box sx={{ textAlign:'center'}}>
                <Typography variant="h4">CLUCKERS CUSTOM KRAX!!! </Typography>
                <Typography>Made by: Mr.Golem</Typography>
                     <LoadableImage imageUrl={'https://ipfs.algonode.xyz/ipfs/QmQ2pZSBpnzHqciZmYg4S8VsLuhj1q5Hg6rxWoBLk1jJZY?optimizer=image&width=1152&quality=100'}/>

                  </Box>
               </Grid>
        
              
</Grid>

             
     
   )
}
export default LandingPage;