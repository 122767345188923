import React, { useState, useEffect } from 'react';
import { TextField, Chip, Stack, Box, Grid } from '@mui/material';

function TagInput({ title,tags, setTags }) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      console.log('enter',inputValue);
      if(!tags){
        setTags([]);
      }
      console.log('tags',tags);

      if (!tags.includes(inputValue)) {
        const newTags = [...tags, inputValue];
        setTags(newTags);  // Update tags in parent component
        setInputValue('');
      }
    }
  };


const handleDelete = (tag)=>{
console.log('delete',tag);
setTags(tags.filter(o=>o!==tag));
}
  return (
    <Box>
      <TextField
        label={title}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        variant="outlined"
        fullWidth
        placeholder={title}
      />
   <Grid container spacing={1} sx={{ marginTop: 2 }}>
      {tags&&tags.map((tag, index) => (
        <Grid item key={index}>
          <Chip
            label={tag}
            onDelete={()=>handleDelete(tag)}
            color="primary"
          />
        </Grid>
      ))}
    </Grid>
    </Box>
  );
}

export default TagInput;
